import styled from "styled-components/macro";
import HomeBack from "../../../assets/images/homeBack.png";
import { withRouter, NavLink, Link } from "react-router-dom";
import mainImage from "../../../assets/images/background.png";

export const InfoContainer = styled.div`
  color: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(to right, #3e41a4 0%, #18a3c6 100%);
`;

export const Column1 = styled.div`
  /* width: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ForgotBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 480px) {
    margin-top: 0rem;
    padding: 0rem 1rem;
  }
`;

export const ForgotHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 420px) {
    font-size: 25px;
    margin-bottom: 0rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

export const ForgotPara = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0px;
`;

export const LoginButton = styled.button`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  width: 100%;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 0px 10px #00000030;
  border-radius: 12px;
  opacity: 1;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "0.5em 2em" : "0.6em 2.5em")};
  border: none;
  background: #ffffff;
  color: #43329f;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }

  @media screen and (max-width: 480px) {
    padding: ${({ big }) => (big ? "0.5em 2em" : "1.2em 1.5em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }
`;

export const LoginBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0px;
`;

export const LogoSarox = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResendOtp = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 1rem;
  cursor: pointer;
`;
