import React, { useState } from "react";
import styled from "styled-components/macro";
import { withRouter, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 18px 18px;
  height: 45px;
  text-decoration: none;
  background: ${({ sidebarActive }) => (sidebarActive ? "#304312" : "#132400")};
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid grey" : "3px solid #132400"};
`;

const SidebarLabel = styled.span`
  margin-left: 16px;

  &:hover {
    color: #21afe6;
  }
`;

const DropdownLink = styled(Link)`
  height: 45px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  background: ${({ sidebarActive }) => (sidebarActive ? "transparent" : "transparent")};
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid none" : "3px solid none"};

  &:hover {
    color: transparent;
    cursor: pointer;
  }
`;

const IconLabel = styled.div`
  width: 100%;
  border-radius: 1.5px;
  display: flex;
  align-items: baseline;
  color: ${({ sidebarActive }) => (sidebarActive ? "#fff" : "grey")};

  &:hover {
    cursor: pointer;
  }
`;

const SidebarIcon = styled.span`
  &:hover {
    color: #21afe6;
  }
`;

const SubMenu = ({ userData, item, history, sidebar, setSidebar }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const showSidebar = () => setSidebar(!sidebar);
  const sidePathname = history.location.pathname.split("/")[1];

  return (
    <>
      <SidebarLink
        sidebarActive={
          history.location.pathname == item.path ||
            sidePathname == item.path.split("/")[1]
            ? true
            : false
        }
        to={item.path}
        onClick={(item.subNav && showSubnav) || (!sidebar ? showSidebar : "")}
      >
        <IconLabel
          sidebarActive={
            history.location.pathname == item.path ||
              sidePathname == item.path.split("/")[1]
              ? true
              : false
          }
        >
          <SidebarIcon>{item.icon}</SidebarIcon>
          <SidebarLabel
            style={{
              color: history.location.pathname == item.path ? "#fff " : "",
              fontWeight: history.location.pathname == item.path ? "400" : "",
            }}
          >
            {item.title}
          </SidebarLabel>
        </IconLabel>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink
              to={item.path}
              sidebarActive={
                history.location.pathname == item.path ||
                  sidePathname == item.path.split("/")[1]
                  ? true
                  : false
              }
              key={index}
            >
              <IconLabel
                style={{
                  color: history.location.pathname == item.path ? "#fff" : "",
                }}
              >
                {/* {item.icon} */}
                <SidebarLabel
                  style={{
                    color: history.location.pathname == item.path ? "#fff" : "",
                    fontWeight:
                      history.location.pathname == item.path ? "500" : "",
                  }}
                >
                  {item.title}
                </SidebarLabel>
              </IconLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubMenu));
