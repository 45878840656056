import React, { useEffect, useState } from 'react'
import "./style.scss"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
} from "@material-ui/core";
import axios from "../../axios";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { get } from 'lodash';
import { IoChevronBack } from 'react-icons/io5';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "95%",
    height:"80vh",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#001050",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
}));
function ViewReferral() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();
  const [tableData, SetTableData] = useState([]);
const{state}=useLocation()
  console.log(param);
  const data = async () => {
    try {
      const resp = await axios.get(`/referralCountView/${param.id}`);
      console.log("resp data--->",resp?.data)
      SetTableData(resp?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    data();
  }, []);

  console.log("table data===>>>>>",tableData)
 // For Pagination
 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(10);
  return (
    <div className='hewe-cont'>
      <div className="flex-align-center">
        <IoChevronBack size={40}  onClick={()=>history.push("/accountManagement",{state:{page:state?.state?.page}})} />
        <h3 className="font-g1 mb-0 ms-3">Referral</h3>
        </div>
        <Paper className={classes.paperTableHeight}>
              <>
                <TableContainer
                  className={classes.tableMainContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell className={classes.tablseHeadingCell}>
                          S.No
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Profile Name
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Email Id
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Phone Number
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Country
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          State
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          City
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          DOB
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Gender
                        </TableCell>
                      
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((category, index) => (
                        <TableRow key={category._id}>
                          <TableCell className={classes.textMiddle}>
                            <div>{index + 1 + page * rowsPerPage}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "profileName", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "email", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>
                              {get(category, "countryCode", "N/A")}
                              {get(category, "phoneNumber", "N/A")}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "country", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "state", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "city", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "dob", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "gender", "N/A") === "1" ? (
                              <span>Male</span>
                            ) : get(category, "gender", "N/A") === "2" ? (
                              <span>Female</span>
                            ) : get(category, "gender", "N/A") === "3" ? (
                              <span>Other</span>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                    
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  className={classes.tablePaginationStyle}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            </Paper>
    </div>
  )
}

export default ViewReferral