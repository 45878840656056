import React, { useState } from "react";
import { IconUser, IconEmail } from "./SvgElements";
import styled from "styled-components";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import passwordIcon from "../assets/images/eye-closed-bold.png";

export default function InputLogin(props) {
  const { error, type, icon, noBorderBottom } = props;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          className={`input_box ${icon ? "borderBottom" : ""} ${
            noBorderBottom ? "" : "borderBottom"
          }`}
        >
          {icon ? <div>{icon}</div> : ""}
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div style={{  }}>
            {" "}
            <input
              {...props}
              type={
                type === "password" ? (showPassword ? "text" : type) : "text"
              }
            />
            {type === "password" ? (
              <div
                style={{
                  position: "absolute",
                  right: "11px",
                  top: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <img alt="icon" src={passwordIcon} style={{color:"#ffffff", height:"18px"}} />
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            {error ? (
              <p
                style={{
                  paddingTop: 5,

                  fontSize: 13,
                  color: "red",
                  textAlign: "left",
                }}
              >
                {error}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
