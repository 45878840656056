import React, { useState, useEffect } from "react";
import { IoIosLogOut } from "react-icons/io";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
  Filter,
  DpBox,
  Fields,
  Resetbtn,
  Applybtn,
} from "./AccountManagementElements";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../components/Input";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaFilter, FaGlobe, FaPhone, FaUser, FaUserAlt } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { BsDot, BsEyeFill } from "react-icons/bs";
import { TiInfoOutline } from "react-icons/ti";
import { GrApple, GrAndroid } from "react-icons/gr";
import { SlClose } from "react-icons/sl";
import { Modal } from "../../components/Modal";
import SearchBar from "material-ui-search-bar";
import { bottom } from "@popperjs/core";
import rn1 from "../../images/hewe/rn1.svg"
import { CSVLink } from 'react-csv';
import {
  MdBlock,
  MdClose,
  MdEmail,
  MdLocalPhone,
  MdOutlineClose,
  MdOutlineFileUpload,
  MdPhone,
} from "react-icons/md";
import {
  IoArchive,
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
  IoChevronBack,
} from "react-icons/io5";
import {
  InputDivide,
  ProfileDetailForm,
} from "../Authentican/Profile/ProfileElements";
import PhoneInput from "react-phone-input-2";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as XLSX from 'xlsx';
import { AdvancedFilterIcon, ArchiveAccount, EyeAccount, UnarchiveAccout } from "../../components/svg";
import { countryNameLists } from "../../components/countryArray";
// drop down
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));

const ArchiveManagement = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const [tableData, SetTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");
  const { state } = useLocation()
  // For Pagination
  const [page, setPage] = useState(state?.state?.page || 0);
  console.log()
  const [statePage, setStatePage] = useState(null)
  console.log("one", statePage)
  // For Search
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(15);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(15);

  console.log("state is ", state?.state?.page)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    window.scrollTo(0, 0)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };
  // Search functionalty
  function myDeb(call, d = 1000) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, d);
    };
  }
  useEffect(() => {
    // Check if `state?.state?.page` exists
    if (state?.state?.page != null) {
      setPage(state.state.page);
      setStatePage(null); // Reset the state page once it's used
    }
  }, [state]);

  useEffect(() => {
    getAccountManageData();
  }, [page, searchTerm]);
  const getAccountManageData = async () => {
    setIsLoading(true);

    try {
      const genderMapping = {
        Male: "1",
        Female: "2",
        Other: "3"
      };

      const deviceTypeMapping = {
        Web: "1",
        Android: "2",
        Ios: "3"
      };


      let formData = {
        country: selectedCountries,
        deviceType: Array.isArray(device)
          ? device.map(d => deviceTypeMapping[d]).filter(Boolean)
          : [deviceTypeMapping[device] || device],
        CurrentRank: rank,
        gender: Array.isArray(gender)
          ? gender.map(g => genderMapping[g]).filter(Boolean)
          : [genderMapping[gender] || gender]
      };
      const { data } = await axios.post(
        `/private/archiveUser?search=${searchTerm}&page=${page + 1}&limit=${limit}`, formData
      );
      setPaginationData(data);
      SetTableData(data?.data);
      setOpenModal(false)
      setShow(false);
      window.scrollTo(0, 0)
    } catch (error) {
      if (error?.response.status === 401) {
        toast.success(`${error.response.data?.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        history.push("/");
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const SearchAccountManagement = myDeb((search) => {
    setSearchTerm(search);
  });

  const cancelSearch = () => {
    setSearchTerm("");
  };

  const block = async (id) => {
    try {
      const response = await axios.put(`/private/changeStatus/${id}`);
      toast.success(response.data.message);
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const archive = async (id) => {
    try {
      let formData = {
        status: "1",
      };
      const response = await axios.put(`/changeAccountStatus/${id}`, formData);
      toast.success(response.data.message);
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const [show, setShow] = useState(false);

  // advance filter

  const genderlist = [
    "Male", "Female"
  ];
  const rankList = [
    "1-10", "11-20",
  ]
  const deviceList = ["Android", "iOS"]
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [gender, setGender] = useState([])
  const [rank, setRank] = useState([])
  const [device, setDevice] = useState([])
  const handleChangegender = (event) => {
    const {
      target: { value },
    } = event;
    setGender(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangerank = (event) => {
    const {
      target: { value },
    } = event;
    setRank(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleChangedevice = (event) => {
    const {
      target: { value },
    } = event;
    setDevice(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleCountryChange = (event) => {
    const { target: { value } } = event;
    setSelectedCountries(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Filter country list based on the search query
  const filteredCountryList = countryNameLists.filter((name) =>
    name.toLowerCase().includes(search.toLowerCase())
  );



  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <div className="mb-3 w-100 px-4">
              <DashboardHeading>
                <div className="dott me-3"></div>
                <DashHeading>Account Management</DashHeading>
              </DashboardHeading>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex align-items-center">
                  <div>
                    <SearchBar
                      style={{ minWidth: "353px", Height: "33px", borderRadius: "4px", marginLeft: "5px", fontStyle: "italic", color: "#FDFFFB" }}
                      className={"heightfix"}
                      onChange={(e) => {
                        SearchAccountManagement(e);
                      }}
                      onCancelSearch={cancelSearch}
                      placeholder="Search Profile"
                      inputProps={{ maxLength: 30 }}
                    />
                  </div>
                  <div className="ms-3 font-dark-green pointer" onClick={() => {
                    setOpenModal(true);
                  }}>
                    <AdvancedFilterIcon /> Advanced Filter
                  </div>
                </div>
                <div>
                  <div className="me-4 d-flex align-items-center">
                    <ExportXLS />
                  </div>
                </div>
              </div>
              <div className="mt-3 ms-1">
                <button className="Archived white-space-nowrap" onClick={() => history.push("/accountManagement")}>Active Users</button>
                <button className="ArchivedActivebtn white-space-nowrap" onClick={() => history.push("/archiveUser")}>Archived USERS</button>
                <button className="Archived white-space-nowrap" onClick={() => history.push("/blockUser")}>Blocked USERS</button>
              </div>
            </div>
            <Paper className={classes.paperTableHeight} onClick={() => setShow(false)}>
              <>
                <TableContainer
                  className={classes.tableMainContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell className={classes.tablseHeadingCell}>
                          S.No
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Action
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Profile Name
                        </TableCell>
                        <TableCell className={[classes.tablseHeadingCell]} style={{ position: "sticky", left: 0, zIndex: 50, background: "white" }}>
                          Email Id
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Country
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Phone Number
                        </TableCell>

                        <TableCell className={classes.tablseHeadingCell}>
                          Gender
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Level
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Rank
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Device
                        </TableCell>
                        {/* <TableCell className={classes.tablseHeadingCell}>
                          Referral Used
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Referral Code
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Referral Count
                        </TableCell> */}

                        <TableCell className={classes.tablseHeadingCell}>
                          Total Distance
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Total Cycling
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((category, index) => (
                        <TableRow key={category._id}>
                          <TableCell className={classes.textMiddle}>
                            <div>{index + 1 + rowsPerPage * page}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div className="d-flex">
                              <div
                                className="font-g2 d-flex align-items-center me-4"
                                onClick={() =>
                                  history.push(
                                    `view-profile/${get(category, "_id")}`, { state: { page: page } }
                                  )
                                }
                              >
                                <EyeAccount />{" "}
                                <span className="ps-2 tu">View</span>
                              </div>

                              {get(category, "archiveStatus") == "2" ? (
                                <>
                                  <div
                                    className="font-r1 d-flex align-items-center me-4"
                                    onClick={() => block(get(category, "_id"))}
                                  >
                                    <MdBlock />
                                    <span className="ps-2 tu">
                                      {get(category, "status") == 1
                                        ? "Unblock"
                                        : "Block"}
                                    </span>
                                  </div>
                                  <div
                                    className="font-b1 d-flex align-items-center me-4"
                                    onClick={() =>
                                      archive(get(category, "_id"))
                                    }
                                  >
                                    <UnarchiveAccout />
                                    <span className="ps-2 tu">Unarchive</span>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "profileName", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle} style={{ position: "sticky", left: 0, zIndex: 50, background: "white" }}>
                            <div>{get(category, "email", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div style={{ whiteSpace: "nowrap" }} className="text-uppercase">
                              <strong>
                                {get(category, "country", "N/A")}
                              </strong>
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div style={{ whiteSpace: "nowrap" }}>
                              {get(category, "countryCode", "N/A")}{" "}
                              {get(category, "phoneNumber", "N/A")}
                            </div>
                          </TableCell>

                          <TableCell className={classes.textMiddle}>
                            {get(category, "gender", "N/A") === "1" ? (
                              <span>Male</span>
                            ) : get(category, "gender", "N/A") === "2" ? (
                              <span>Female</span>
                            ) : get(category, "gender", "N/A") === "3" ? (
                              <span>Other</span>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div><img src={rn1} alt="" /></div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                          <div>{get(category, "CurrentRank", "N/A")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "deviceType", "N/A") === "1" ? (
                              <FaGlobe
                                style={{ fontSize: "20px", color: "#000000" }}
                              />
                            ) : get(category, "deviceType", "N/A") === "2" ? (
                              <GrAndroid
                                style={{ fontSize: "20px", color: "#79c257" }}
                              />
                            ) : get(category, "deviceType", "N/A") === "3" ? (
                              <GrApple
                                style={{ fontSize: "20px", color: "#424245" }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          {/* <TableCell className={classes.textMiddle}>
                            {get(category, "referralCode", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "userReferralCode", "N/A")}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "referralCount", "0")} <br />{" "}
                            {
                              get(category, "referralCount", "0")!=0? <span
                              className="white-space-nowrap pointer underline"
                              onClick={() =>
                                history.push(
                                  `view-referral/${get(
                                    category,
                                    "userReferralCode"
                                  )}`,{state:{page:page}}
                                )
                              }
                            >
                              View Details
                            </span>:null
                            }
                           
                          </TableCell> */}

                          <TableCell className={classes.textMiddle}>
                            {get(category, "TotalWalk", "N/A")?.toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {get(category, "TotalCycling", "N/A")?.toFixed(2)}
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className={classes.tablePaginationStyle}
                  rowsPerPageOptions={[15]}
                  component="div"
                  count={paginationData?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>

      {/* Modal for filter  */}
      <Modal
        maxWidth="lg"
        width="680px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="ps-2">Advanced Filter</p>
              </div>
              <MdOutlineClose
                style={{
                  fontWeight: 600,
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>

          </div>
        }
        content={
          <>
            <div className="d-flex align-items-center">
              <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-checkbox-label" className="font-italic">Select Country</InputLabel>
                  <Select
                    labelid="country-select-label"
                    id="country-select"
                    multiple
                    value={selectedCountries}
                    onChange={handleCountryChange}
                    input={<OutlinedInput label="Select Country" />}
                    renderValue={(selected) => selected.join(', ')}
                    style={{ minHeight: '3.4em' }}
                    MenuProps={{
                      disablePortal: true,
                      PaperProps: {
                        onMouseDown: (event) => event.stopPropagation(),
                      },
                    }}
                  >
                    {/* Search Input Outside of Menu Items */}
                    {/* <MenuItem disableRipple style={{ padding: 0 }}> */}
                    <InputBase
                      placeholder="Search..."
                      value={search}
                      className="w-100"
                      onChange={handleSearchChange}
                      style={{
                        borderBottom: "1px solid gray",
                        padding: '8px',
                        fontSize: '0.9rem',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}
                    />
                    {/* </MenuItem> */}

                    {/* Filtered List of Countries */}
                    {filteredCountryList.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedCountries.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-checkbox-label" className="font-italic">Select Gender</InputLabel>
                  <Select
                    style={{ minHeight: "3.4em" }}
                    labelid="demo-multiple-checkbox-label" className="font-italic"
                    id="demo-multiple-checkbox"
                    multiple
                    value={gender}
                    onChange={handleChangegender}
                    input={<OutlinedInput label="Select Gender" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >

                    {genderlist.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={gender.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-checkbox-label" className="font-italic">Select Rank</InputLabel>
                  <Select
                    style={{ minHeight: "3.4em" }}
                    labelid="demo-multiple-checkbox-label" className="font-italic"
                    id="demo-multiple-checkbox"
                    multiple
                    value={rank}
                    onChange={handleChangerank}
                    input={<OutlinedInput label="Select Rank" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {rankList.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={rank.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-checkbox-label" className="font-italic">Select Device</InputLabel>
                  <Select
                    style={{ minHeight: "3.4em" }}
                    labelid="demo-multiple-checkbox-label" className="font-italic"
                    id="demo-multiple-checkbox"
                    multiple
                    value={device}
                    onChange={handleChangedevice}
                    input={<OutlinedInput label="Select Device" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {deviceList.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={device.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="d-flex justify-content-end my-3">
              <button className="ArchivedActivebtn" onClick={() => getAccountManageData()}>Apply</button>
            </div>
          </>
        }
      />

      {isLoading && <Overlay />}
    </>
  );
};

export const ExportXLS = () => {
  const fileName = "users-detail.xls";
  const [userData, setUserData] = useState([]);

  console.log("userData is ", userData);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    axios.get('/private/archivetxls')
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet(userData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className='container'>
      <button className="ExportXls" onClick={exportToXLS}>
        <MdOutlineFileUpload size={20} /> Export XLS
      </button>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArchiveManagement));
