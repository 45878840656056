import React, { useState, useEffect } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";




const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));

const Rewardmngt = (id) => {
    let userId = id?.id
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(0);
    const [statePage, setStatePage] = useState(null)
    console.log("one", statePage)
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        window.scrollTo(0, 0)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const getData = async () => {
        try {
            const { data } = await axios.get(
                `/private/spinRewardbyUserId/${userId}?page=${page + 1}&limit=${limit}`
            );
            console.log(data?.data?.docs)
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData();
    }, [page]);


    const categoryNames = {
        "1": "Vouchers",
        "2": "In-App Rewards",
        "3": "Audit",
        "4": "Premium Rewards",
        "5": "Trip Rewards"
    };


    return (
        <>
            <div>
                <div>
                    <div>
                        <Paper className={classes.paperTableHeight}>
                            <>
                                <TableContainer
                                    className={classes.tableMainContainer}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow className={classes.tableHeadingRow}>
                                                <TableCell className={classes.tablseHeadingCell}>
                                                    S.No
                                                </TableCell>

                                                <TableCell className={classes.tablseHeadingCell}>
                                                    Type of Reward
                                                </TableCell>
                                                <TableCell className={[classes.tablseHeadingCell]}>
                                                    Date Earned
                                                </TableCell>
                                                <TableCell className={classes.tablseHeadingCell}>
                                                    Quantity
                                                </TableCell>
                                                <TableCell className={classes.tablseHeadingCell}>
                                                    Redemption Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData.map((category, index) => (
                                                <TableRow key={category._id}>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{index + 1 + rowsPerPage * page}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{categoryNames[get(category, "rewardInfo.categoryName", "N/A")] || "N/A"}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{get(category, 'createdAt', 'N/A') ? new Date(get(category, 'createdAt')).toLocaleDateString("en-GB") : 'N/A'}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "rewardInfo.rewardAmount", "N/A")}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            {get(category, "rewardInfo.categoryName") === "2" ? new Date(get(category, "createdAt", "N/A")).toLocaleDateString("en-GB") : null}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    className={classes.tablePaginationStyle}
                                    rowsPerPageOptions={[15]}
                                    component="div"
                                    count={paginationData?.totalDocs}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        </Paper>
                    </div>
                </div>
            </div>



            {isLoading && <Overlay />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Rewardmngt));
