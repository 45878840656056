import React, { useState } from "react";

import {
  InfoContainer,
  Column1,
  TextWrapper,
  ForgotBox,
  ForgotHeading,
  ForgotPara,
  InputBox,
  LoginButton,
  LoginBtnWrapper,
  LogoSarox,
} from "./ForgotElements";
import { Formik, Field, Form } from "formik";
import InputLogin from "../../../components/InputLogin";
import { Link, useHistory } from "react-router-dom";
import Overlay from "../../../components/Overlay";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { makeStyles } from "@material-ui/core/styles";
import logoAboveForgot from "../../../assets/images/logo-white.png";
import dotImage from "../../../assets/images/dot-img.png";
import "./forgotPassword.css";
import { resetPassword } from "../../../utils/validators";
import { FaArrowLeft } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  formStyle: {
    width: "100%",
    padding: "2rem",
    overflow: "scroll",
  },
  "@media (max-width: 780px)": {
    formStyle: {
      padding: "1.8rem",
    },
    formStyleOnly: {
      padding: "1.8rem",
    },
  },
  "@media (max-width: 480px)": {
    formStyle: {
      padding: "1.3rem",
    },
    formStyleOnly: {
      padding: "1.3rem",
    },
  },

  formStyleOnly: {
    width: "100%",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userEmail = localStorage.getItem("email") || sessionStorage.getItem("token");

  const [loginValues, setLoginValues] = useState({
    email: "",
    new_password: "",
    confirm_password: "",
  });

  const handleResetPassword = async (values) => {
    setLoginValues(values);
    setIsLoading(true);

    var formvalues = {
      email: userEmail,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    };

    try {
      const { data } = await axios.post("/reset_password", formvalues);

      if (data.status === 200) {
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push("/");
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <InfoContainer>
        <Column1>
          <TextWrapper>
            <ForgotBox>
              {/* <LogoSarox>
                <img className="web-logo" src={logoAboveForgot} />
              </LogoSarox> */}
              <ForgotHeading>Reset Password</ForgotHeading>
              <ForgotPara>Please create your new password!</ForgotPara>
            </ForgotBox>
            <InputBox>
              <Formik
                enableReinitialize
                initialValues={loginValues}
                validate={resetPassword}
                validateOnChange
                onSubmit={handleResetPassword}
              >
                {(formikBag) => {
                  return (
                    <Form className={classes.formStyleOnly}>
                      <div className="reset-password">
                        <Field name="new_password">
                          {({ field }) => (
                            <InputLogin
                              {...field}
                              type="password"
                              value={formikBag.values.new_password}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "new_password",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.new_password &&
                                formikBag.errors.new_password
                                  ? formikBag.errors.new_password
                                  : null
                              }
                              className="form-control signup-input-style"
                              placeholder="New Password"
                            />
                          )}
                        </Field>
                        <Field name="confirm_password">
                          {({ field }) => (
                            <InputLogin
                              {...field}
                              type="password"
                              value={formikBag.values.confirm_password}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "confirm_password",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.confirm_password &&
                                formikBag.errors.confirm_password
                                  ? formikBag.errors.confirm_password
                                  : null
                              }
                              className="form-control signup-input-style"
                              placeholder="Confirm Password"
                            />
                          )}
                        </Field>
                      </div>

                      <LoginBtnWrapper>
                        <LoginButton>Reset Password</LoginButton>
                      </LoginBtnWrapper>
                    </Form>
                  );
                }}
              </Formik>
            </InputBox>

            <div style={{ position: "absolute", bottom: "0", width: "95%" }}>
              <img alt="dot-img" src={dotImage} style={{ width: "100%" }} />
            </div>
          </TextWrapper>
        </Column1>
      </InfoContainer>
      {isLoading && <Overlay />}
    </>
  );
};

export default ResetPassword;
