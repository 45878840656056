import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';

function ReferralAndEarn() {
  const [senderValue, setSenderValue] = useState()
  const formData = {
    id: "66b9f0464b802321a3a2697a",
    value: senderValue,
  }

  const getSenderValue = async () => {
    try {
      const res = await axios.get(`/findRewardValueById/${formData.id}`);
      setSenderValue(res.data.data[0].value)
      toast.success(res.data.message)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getSenderValue()
  }, [])
  const SenderData = async () => {
    try {
      const res = await axios.post("/private/setRewardType", formData);
      toast.success(res.data.message)
      getSenderValue()
    } catch (error) {
      console.log(error)
    }
  }

  const [receivedValue, setReceivedValue] = useState()
  const receivedFormData = {
    id: "66b9f0374b802321a3a26974",
    value: receivedValue,
  }

  const getReceivedValue = async () => {
    try {
      const res = await axios.get(`/findRewardValueById/${receivedFormData.id}`);
      setReceivedValue(res.data.data[0].value)
      toast.success(res.data.message)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getReceivedValue()
  }, [])
  const receivedData = async () => {
    try {
      const res = await axios.post("/private/setRewardType", receivedFormData);
      toast.success(res.data.message)
    } catch (error) {

    }
  }


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Refer & Earn</p>
          </div>
          <div className='desc my-4'>
            <p>Define the reward users will earn for each successfull referral. Customize the hewe coins they recived based on their referral activity</p>
          </div>
          <div className="refcont">
            <h2>For Sender</h2>


            <div className='flex-align-center mt-5'>
              <h4 className='mb-0 perrefer'>per refer = </h4>
              <div className='ms-3'>
                <label htmlFor="Total Hewe coins">Total Hewe coins</label>
                <div>
                  <input type="text" placeholder='Enter hewe coins' value={senderValue} onChange={(e) => setSenderValue(e.target.value)} />
                </div>
              </div>
            </div>
            <button onClick={() => SenderData()}>Update</button>
          </div>
          <div className="refcont">
            <h2>For Receiver</h2>


            <div className='flex-align-center mt-5'>
              <h4 className='mb-0 perrefer'>per refer = </h4>
              <div className='ms-3'>
                <label htmlFor="Total Hewe coins">Total Hewe coins</label>
                <div>
                  <input type="text" placeholder='Enter hewe coins' value={receivedValue} onChange={(e) => setReceivedValue(e.target.value)} />
                </div>
              </div>
            </div>
            <button onClick={() => receivedData()}>Update</button>
          </div>

        </div>
      </div>

    </>
  )
}

export default ReferralAndEarn;