import React, { useState } from "react";
import Cookies from "js-cookie";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
  MenuAndBack,
  ProfileDetailForm,
  LoginButton,
} from "../Profile/ProfileElements";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import InputLogin from "../../../components/InputLogin";
import axios from "../../../axios";
import Overlay from "../../../components/Overlay";
import Input from "../../../components/Input";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { withRouter, Link, useHistory } from "react-router-dom";
import { changedPasswordValidator } from "../../../utils/validators";
import "../Profile/profile.css";
import "./forgotPassword.css";
import { IoMdLock } from "react-icons/io";
import { BsDot } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablePadding: {
    padding: "0.5rem",
    textAlign: "center",
    fontSize: "0.8rem",
  },

  paperTableHeight: {
    // margin:"auto",
    // width: "80%",
    marginLeft: "2rem",
  },

  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ChangedPassword = ({ setUsers, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("token") || sessionStorage.getItem("token");

  const [changedPassword, setchangedPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirm_password: "",
  });

  const handleChangedPassword = async (values) => {
    setIsLoading(true);

    var fromData = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirm_password: values.confirm_password,
    };

    try {
      const { data } = await axios.put(`/private/changePassword`, fromData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const itemsToRemove = ["token", "userData", "email", "deviceId"];

      itemsToRemove.forEach((item) => {
        localStorage.removeItem(item);
        sessionStorage.removeItem(item);
      });
      Cookies.remove("showProfileSidebar");
      setUsers("");

      toast.success(`${data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push("/");

    } catch (error) {
      if (error.response.status === 401) {
        toast.error(`${error.response.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(error.response.data?.errors[0]?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="hewe-cont">
      <DashboardWrapper>
        <div className="flex-align-center mb-4">
          <div className="dott me-3"></div>
          <DashHeading>Change Password</DashHeading>
        </div>

        <Paper className="">
          <ProfileDetailForm>
            <Formik
              enableReinitialize
              initialValues={changedPassword}
              validate={changedPasswordValidator}
              validateOnChange
              onSubmit={(values) => handleChangedPassword(values)}
            >
              {(formikBag) => {
                return (
                  <Form className="changed-password-form">
                    <div className="changed-password-container row">
                      <div>
                        <label className="changed-label-name">
                          Old Password
                        </label>
                        <div className="form-controlcont form-controlcont1  justify-content-between">
                          <IoMdLock
                            style={{ fontSize: "18px", color: "#88AE40" }}
                          />
                          <Field name="oldPassword">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="password"
                                value={formikBag.values.oldPassword}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "oldPassword",
                                    e.target.value
                                  );
                                }}
                                maxLength={20}
                                className="form-control w-100"
                                placeholder="Enter Old Password"
                              />
                            )}
                          </Field>
                        </div>
                        <p className="error">
                          {formikBag.touched.oldPassword &&
                            formikBag.errors.oldPassword
                            ? formikBag.errors.oldPassword
                            : null}{" "}
                        </p>
                      </div>
                      <div>
                        <label className="changed-label-name">
                          New Password
                        </label>
                        <div className="form-controlcont form-controlcont1   justify-content-between">
                          <IoMdLock
                            style={{ fontSize: "18px", color: "#88AE40" }}
                          />
                          <Field name="newPassword">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="password"
                                value={formikBag.values.newPassword}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "newPassword",
                                    e.target.value
                                  );
                                }}
                                className="form-control w-100"
                                maxLength={20}
                                placeholder="Enter New Password"
                              />
                            )}
                          </Field>
                        </div>
                        <p className="error">
                          {formikBag.touched.newPassword &&
                            formikBag.errors.newPassword
                            ? formikBag.errors.newPassword
                            : null}
                        </p>
                      </div>
                      <div>
                        <label className="changed-label-name">
                          Confirm Password
                        </label>
                        <div className="form-controlcont form-controlcont1 justify-content-between">
                          <IoMdLock
                            style={{ fontSize: "18px", color: "#88AE40" }}
                          />
                          <Field name="confirm_password">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="password"
                                value={formikBag.values.confirm_password}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "confirm_password",
                                    e.target.value
                                  );
                                }}
                                className="form-control w-100"
                                placeholder="Enter Confirm Password"
                                maxLength={20}
                              />
                            )}
                          </Field>
                        </div>
                        <p className="error">
                          {formikBag.touched.confirm_password &&
                            formikBag.errors.confirm_password
                            ? formikBag.errors.confirm_password
                            : null}
                        </p>
                      </div>
                      <div className="mt-3 d-flex align-items-center justify-content-center">
                        <LoginButton
                          type="submit"
                          className="buttonWidthResponsive"
                        >
                          Save
                        </LoginButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </ProfileDetailForm>
        </Paper>
      </DashboardWrapper>

      {isLoading && <Overlay />}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangedPassword));
