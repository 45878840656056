import React from "react";

import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as mdIcons from "react-icons/md";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { HomeIcon } from "../svg";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "Account Management",
    path: "/accountManagement",
    icon: <FaIcons.FaUser />,
  },
  // {
  //   title: "Reward Management",
  //   path: "/rewardManagement",
  //   icon: <FaIcons.FaMoneyBill />,
  // },
  {
    title: "Reward Management",
    path: "/refer-and-earn",
    icon: <FaIcons.FaTrophy />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Refer & Earn",
        path: "/refer-and-earn",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Other Reward",
        path: "/other-reward",
        icon: <BsIcons.BsCardList />,
      },

    ],
  },
  {
    title: "Level Management",
    path: "/level-management",
    icon: <FaIcons.FaMedal />,
  },
];

export const profileSidebarData = [
  {
    title: "My Profile",
    path: "/profile",
    icon: <FaIcons.FaUser />,
  },
  {
    title: "Manage Devices",
    path: "/manageDevice",
    icon: <HiOutlineDeviceMobile />,
  },
  {
    title: "Change Password",
    path: "/changedPassword",
    icon: <FaIcons.FaLock />,
  },
];
