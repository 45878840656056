export const DateFormater = (paylaod) => {

    return (
        <>
            <div>
                {new Date(paylaod?.item).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}
            </div>
        </>
    )
}
