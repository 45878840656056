import React, { useState } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { IoChevronBack } from 'react-icons/io5'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Field, Form, Formik } from 'formik';
import Input from '../../components/Input';
import { Modal } from '../../components/Modal';
import { MdOutlineClose } from 'react-icons/md';
import CreateLevelVoucher from '../../components/levelComponent/voucher/create-Level-vouchers';
import EditLevelVoucher from '../../components/levelComponent/voucher/edit-Level-voucher';
import CreateLevelInAppReward from '../../components/levelComponent/inAppReward/create-Level-appReward';
import CreateLevelAuditToken from '../../components/levelComponent/auditToken/create-Level-AuditToken';
import CreateLevelPremium from '../../components/levelComponent/premium/create-Level-premium';
import CreateLevelTrip from '../../components/levelComponent/trip/create-Level-trip';
import { addLevelValidation } from '../../utils/validators';
import axios from "../../axios";
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';

export default function AddLevel() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [rewardType, setRewardType] = useState();
    const [openModal, setOpenModal] = useState(false);
    console.log(rewardType);

    const [compData, setCompData] = useState({})
    const handleDataFromChild = (childData, setOpenModal) => {
        setCompData(childData);
        console.log('Data received from child:', childData);

    };

    const [categoryName, setCategoryName] = useState("1")
    const [levelData, setLevelData] = useState({
        level: "",
        targetedHealth: "",
        targetedWealth: "",
        categoryName: "",
    })


    const RewardType = [
        { level: "Vouchers", value: "1" },
        { level: "InAppRewards", value: "2" },
        { level: "Audit", value: "3" },
        { level: "PremiumRewards", value: "4" },
        { level: "tripRewards", value: "5" }
    ]

    const getModalValue = (value) => {
        setRewardType(value)
        setOpenModal(true)
    }
    const handleAddLevel = async (value) => {
        setIsLoading(true);
        try {
            let formData = {
                level: value.level,
                targetedHealth: value.targetedHealth,
                targetedWealth: value.targetedWealth,
                categoryName: value.categoryName,
                Reward: { ...compData }
            }
            const data = await axios.post("/private/createlevel", formData);

            toast.success(data?.data?.message);
            history.push("/level-management")
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data?.errors[0].msg)
        }
    }
    return (
        <>
            <div className='hewe-cont addlevel'>
                <div className="flex-align-center">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.push("/level-management")
                        }
                    />
                    <DashHeading>Add Level</DashHeading>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={levelData}
                    validate={addLevelValidation}
                    validateOnChange
                    onSubmit={(values) => handleAddLevel(values)}
                >
                    {(formikBag) => {
                        return (
                            <Form className="p-3">
                                {console.log(formikBag)}
                                <div className="bg-white p-4">
                                    <div className="row">
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Level</label>
                                            <div className="form-controlcont">
                                                <Field name="level w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.level}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "level",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Level"
                                                            minLength="1"
                                                            maxlength="3"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.level &&
                                                    formikBag.errors.level
                                                    ? formikBag.errors.level
                                                    : null
                                            }</p>
                                        </div>
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Health Goal</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedWealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.targetedWealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedWealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Coins"
                                                            maxlength="12"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>
                                                {formikBag.touched.targetedWealth &&
                                                    formikBag.errors.targetedWealth
                                                    ? formikBag.errors.targetedWealth
                                                    : null
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Wealth Goal</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedHealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="text"
                                                            value={formikBag.values.targetedHealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedHealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter distance"
                                                            maxlength="12"
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.targetedHealth &&
                                                    formikBag.errors.targetedHealth
                                                    ? formikBag.errors.targetedHealth
                                                    : null
                                            }</p>
                                        </div>
                                        <div className="col-md-4" style={{ padding: "1rem" }}>
                                            <label>Reward Type</label>
                                            <div className="form-controlcont">
                                                <select
                                                    className="redwardTypeselect"
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        if (formikBag.values.categoryName !== selectedValue) {
                                                            getModalValue(selectedValue);
                                                        }
                                                        formikBag.setFieldValue("categoryName", selectedValue);
                                                        setCategoryName(selectedValue);
                                                    }}
                                                    value={formikBag.values.categoryName || ""}
                                                >
                                                    <option value="" disabled>
                                                        Select Type
                                                    </option>
                                                    {RewardType?.map((item) => (
                                                        <option key={item.value} value={item.value}>
                                                            {item.level}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.categoryName &&
                                                    formikBag.errors.categoryName
                                                    ? formikBag.errors.categoryName
                                                    : null
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="" style={{ padding: "1rem" }}>
                                        <button
                                            type="submit"
                                            className="savebtn"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <Modal
                maxWidth="lg"
                width="100%"
                RoundedCorners={true}
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2 main-heading">
                                    Add{" "}
                                    {categoryName === "1"
                                        ? "Voucher"
                                        : categoryName === "2"
                                            ? "In App Reward"
                                            : categoryName === "3"
                                                ? "Audit Reward"
                                                : categoryName === "4"
                                                    ? "Premium Reward"
                                                    : categoryName === "5"
                                                        ? "Trip Reward"
                                                        : ""}
                                </p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <>
                        {
                            rewardType == "1" ? <CreateLevelVoucher rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "2" ? <CreateLevelInAppReward rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "3" ? <CreateLevelAuditToken rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "4" ? <CreateLevelPremium rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "5" ? <CreateLevelTrip rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : null
                        }
                    </>
                }
            />
            {isLoading && <Overlay />}

        </>

    )
}
