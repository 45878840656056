import React, { useEffect, useState } from "react";
import "./style.scss";
import axios from "../../axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { IoArchive, IoChevronBack } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { AppliedCuponIcon, CycleIcon, FootIcon, JoinIcon, PhoneIcon, UserIcon, UserIdIcon } from "../svg";
import awardimg from "../../images/hewe/award.svg";
import maleProfileImg from "../../images/hewe/maleProfile.png"
import { DateFormater, DateFormater1 } from "../dateFormater";
import Rewardmngt from "./rewardmngt";
import WalkingHistory from "./walkingHistory";
import CyclingHistory from "./cyclingHistory";

function ViewProfile() {
  const [stateValue, setStateValue] = useState(0);
  const Items = [
    { label: 'Reward Management', value: 0 },
    { label: 'Walking History', value: 1 },
    { label: 'Cycling History', value: 2 },
  ];

  const history = useHistory();
  const { state } = useLocation();
  console.log(state?.state?.page);
  const param = useParams();
  const [profile, setProfile] = useState();
  const [rank,setRank]=useState()
  console.log(param);
  const data = async () => {
    try {
      const resp = await axios.get(`/getProfileById/${param.id}`);
      setProfile(resp?.data?.data);
      setRank(resp?.data?.currenRank)
      
    } catch (error) { }
  };
  useEffect(() => {
    data();
  }, []);


  console.warn(rank)



  const handleClick = (value) => {
    setStateValue(value);
    sessionStorage.setItem('spinReward', value);
  };

  console.log(profile);
  return (
    <section className="hewe-cont">
      <div className="viewProfile">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-align-center">
            <IoChevronBack
              size={32}
              className="back-color"
              onClick={() =>
                history.push("/accountManagement", {
                  state: { page: state?.state?.page },
                })
              }
            />
            <h5 className="back-color mb-0 ms-2">Back</h5>
          </div>
          <div className="flex-align-center">
            <div className="spending">Total Spending <span>$0</span></div>
            <div className="referralCode ms-3">Referral Code <span>{profile?.userReferralCode}</span></div>
          </div>
        </div>
        <div className="statcont">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center py-3 px-4 ">
              <div className="booster">
                <h2>Daily Boosters</h2>
                <div className="ms-1">
                  <h4>ARCHERY <span>3</span></h4>
                  <h3>
                    USED: {3 - profile?.dailyArrowCount}
                    <span>/3</span>
                  </h3>
                </div>
                <div className="ms-4">
                  <h4>SPIN <span>5</span></h4>
                  <h3>USED:{5 - profile?.dailySpinCount}<span>/5</span></h3>
                </div>
                <div className="ms-4">
                  <h4>BALL <span>3</span></h4>
                  <h3>
                    USED: {3 - profile?.dailyBallCount}
                    <span>/3</span>
                  </h3>
                </div>
              </div>
              <div className="ms-4">
                <div className="otherdetail">
                  <CycleIcon /> <span className="ms-3 text-b1">{(profile?.TotalCycling ?? 0).toFixed(2)}</span>
                </div>
                <div className="otherdetail mt-2">
                  <FootIcon /> <span className="ms-3 text-p1">{(profile?.TotalWalk ?? 0).toFixed(2)}</span>
                </div>
              </div>
              <div className="ms-3">
                <div className="otherdetail text-y1">
                  Total HEWE Coins <span className="text-black ms-2">{profile?.HeweCoins}</span>
                </div>
                <div className="otherdetail mt-2 font-g1">
                  Total AMC Coins <span className="text-black ms-2">{profile?.amcCoins}</span>
                </div>
              </div>
            </div>
            <div className="award mx-5">
              <div className=" w-fit-content">
                <div className="imgcont">
                  <img src={awardimg} alt="img" />
                  <p className="level">{profile?.CurrentLevel}</p>
                </div>
              </div>
              <h4 className="rank">Rank <span>{rank}</span></h4>
            </div>
          </div>
        </div>

        <div className="flex-align-center">
          <div className="profile">
            <div>
              <div className="profileImg">
                <img src={maleProfileImg} alt="img" className="img-fluid" />
                {
                  profile?.accountStatus == "1" ? <div className="activeDot"></div> : null
                }

              </div>
              <div className="data">
                <h2 className="flex-align-center">
                  <UserIcon /> <strong className="ms-2">{profile?.profileName}</strong>
                </h2>
                <h3 className="flex-align-center">
                  <UserIdIcon /> <strong className="mx-2 ">UserId: </strong> {profile?._id}
                </h3>
                <h3 className="flex-align-center">
                  <JoinIcon /> <strong className="mx-2">Joined on : </strong> <DateFormater item={profile?.createdAt} />
                </h3>
                <h3 className="flex-align-center">
                  <PhoneIcon /> <strong className="mx-2">Phone No : </strong> {profile?.countryCode}{profile?.phoneNumber}
                </h3>
                <h3 className="flex-align-center">
                  <AppliedCuponIcon /> <strong className="mx-2">Applied Code : </strong>{profile?.referralCode}
                </h3>
              </div>
            </div>
          </div>
          <div className="personalData ms-3">
            <h2>Personal Details</h2>

            <div className="row justify-content-between mt-2">
              <div className="col-4 mb-4">
                <label htmlFor="">Email Address</label>
                <div className="input">
                  {profile?.email}
                </div>
              </div>
              <div className="col-4 mb-4">
                <label htmlFor="">Location</label>
                <div className="input">
                  {profile?.city},{profile?.state}
                </div>
              </div>
              <div className="col-4 mb-4">
                <label htmlFor="">Device Type</label>
                <div className="input">
                  {(() => {
                    switch (profile?.deviceType) {
                      case "1":
                        return "web";
                      case "2":
                        return "Android";
                      case "3":
                        return "iOS";
                      default:
                        return null;
                    }
                  })()}
                </div>
              </div>
              <div className="col-4 mb-4">
                <label htmlFor="">Date of Birth</label>
                <div className="input">
                  {profile?.dob}
                </div>
              </div>
              <div className="col-4 mb-4">
                <label htmlFor="">Country</label>
                <div className="input font-uppercase">
                  <strong>{profile?.country}</strong>
                </div>
              </div>
              <div className="col-4 mb-4">
                <label htmlFor="">Device Id</label>
                <div className="input">
                  {profile?.deviceId}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="statbtn mb-3">

          {Items.map((item, index) => (
            <div key={index}>
              <div
                className="cont"
                onClick={() => handleClick(item.value)}
                style={
                  stateValue === item.value
                    ? { backgroundColor: '#599265E0', color: 'white' }
                    : { backgroundColor: '#EFEFEF', color: "#212121" }
                }
              >
                <p>{item.label}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="tableData">
          {stateValue === 0 ? (
            <Rewardmngt id={param.id} />
          ) : stateValue === 1 ? (
            <WalkingHistory id={param.id} />
          ) : stateValue === 2 ? (
            <CyclingHistory id={param.id} />
          ) : null
          }
        </div>
      </div>
    </section>
  );
}

export default ViewProfile;
