import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import mainImage from "../../../assets/images/background.png";

export const InfoContainer = styled.div`
  color: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(to right, #3e41a4 0%, #18a3c6 100%);
  /* background: url(${mainImage}) no-repeat center top / cover; */

  @media screen and (max-width: 768px) {
    grid-template-areas: auto auto;
  }
`;

export const Column1 = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(120deg, #fff9fb 0%, #f6e3ff 100%) 0%
    0% no-repeat padding-box;
  background: url(${mainImage}) no-repeat center top / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SignupBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 480px) {
    margin-top: 0rem;
    padding: 0rem 1rem;
  }
`;

export const SignupHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 420px) {
    font-size: 25px;
    margin-bottom: 0rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

export const SignupPara = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
`;

export const ThankYouHeading = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 420px) {
    font-size: 25px;
    margin-bottom: 0rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

export const ThankYouPara = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0px;
`;

export const LoginButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoginButton = styled.button`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  width: 100%;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 0px 10px #00000030;
  border-radius: 12px;
  opacity: 1;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "0.5em 2em" : "0.6em 2.5em")};
  border: none;
  background: #ffffff;
  color: #43329f;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }

  @media screen and (max-width: 480px) {
    padding: ${({ big }) => (big ? "0.5em 2em" : "1.2em 1.5em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }
`;

export const LabelHeading = styled.div`
  color: #000000;
  text-align: left;
  font-size: 22px;
`;

export const LabelPara = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  color: #000000;
  font-size: 18px;
`;

export const SelectServiceBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  @media screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ProfileTime = styled.div`
  display: flex;
  width: 59%;
  justify-content: flex-end;
  align-items: center;
`;

export const ProfileDayTime = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchIcon = styled.span`
  width: 8%;
  padding: 5px;
  text-align: center;
  color: #000000;
  background: #ffffff;
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginButtonLink = styled(Link)`
  box-shadow: 0px 0px 10px #00000030;
  border-radius: 32px;
  opacity: 1;
  margin: 0.4em;
  width: 100%;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "0.5em 2em" : "1.2em 1.5em")};
  border: none;
  font-weight: 700;
  background: ${({ primary }) =>
    primary
      ? "#F9ECFD"
      : "transparent linear-gradient(90deg, #E42279 0%, #6C1D63 100%) 0% 0% no-repeat padding-box"};
  color: ${({ primary }) => (primary ? "#000000" : "#FFFFFF")};
  font-size: ${({ fontBig }) => (fontBig ? "1.2rem" : "1rem")};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "1.2em 1.5em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }

  @media screen and (max-width: 480px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "1.2em 1.5em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1rem")};
  }
`;

export const LoginBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const LogoSarox = styled.div`
  display: flex;
  justify-content: center;
`;

export const BackIcon = styled.div`
  color: #000000;
  cursor: pointer;
  padding-right: 5px;
  margin-right: 10px;
`;

export const HeadingButton = styled.button`
  box-shadow: 0px 0px 10px #00000030;
  border-radius: 5px;
  opacity: 1;
  margin: 0.4em;
  white-space: nowrap;
  border: none;
  font-weight: ${({ dropDown }) => (dropDown ? "600" : "500")};
  padding: ${({ dropDown }) => (dropDown ? "0.7em 1.6em" : "0.9em 1.3em")};
  background: ${({ dropDown }) =>
    dropDown
      ? "#FFFFFF"
      : "transparent linear-gradient(90deg, #E42279 0%, #6C1D63 100%) 0% 0% no-repeat padding-box"};
  color: ${({ dropDown }) => (dropDown ? "#000000" : "#FFFFFF")};
  font-size: ${({ dropDown }) => (dropDown ? "1rem" : "1.2rem")};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: ${({ primary }) => (primary ? "center" : "space-between")};
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-bottom: 1rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "0.9em 1.3em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1em")};
  }

  @media screen and (max-width: 480px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "0.9em 1.3em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "1em")};
  }
`;
