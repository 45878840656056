import React, { useState, useEffect } from "react";

import {
  InfoContainer,
  Column1,
  TextWrapper,
  ForgotBox,
  ForgotHeading,
  ForgotPara,
  InputBox,
  LoginButton,
  LoginBtnWrapper,
  LogoSarox,
  ResendOtp,
} from "../ForgotPassword/ForgotElements";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { withRouter, Link, useHistory } from "react-router-dom";
import Overlay from "../../../components/Overlay";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import dotImage from "../../../assets/images/dot-img.png";
import "../ForgotPassword/forgotPassword.css";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  formStyle: {
    width: "100%",
    padding: "2rem",
    overflow: "scroll",
  },
  "@media (max-width: 780px)": {
    formStyle: {
      padding: "1.8rem",
    },
    formStyleOnly: {
      padding: "1.8rem",
    },
  },
  "@media (max-width: 480px)": {
    formStyle: {
      padding: "1.3rem",
    },
    formStyleOnly: {
      padding: "1.3rem",
    },
  },

  formStyleOnly: {
    width: "100%",
  },
}));

const OtpVerify = ({ setUsers, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const userEmail = localStorage.getItem("email");

  // Verify Otp
  const handleOtpVerify = async (values) => {
    setIsLoading(true);

    var formvalues = {
      email: userEmail,
      otp: otp,
    };

    try {
      const { data } = await axios.post("/verify_otp", formvalues);

      if (data.status === 200) {
        toast.success(`${data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Resend otp
  const handleResend = async () => {
    setIsLoading(true);

    var formvalues = {
      email: userEmail
    };

    try {
      const { data } = await axios.post("/send_otp", formvalues);

      if (data.status === 200) {
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }
  
  return (
    <>
      <InfoContainer>
        <Column1>
          <TextWrapper>
            <ForgotBox>
              {/* <LogoSarox>
                <img className="web-logo" src={logoAboveForgot} />
              </LogoSarox> */}
              <ForgotHeading>Verify Email Address</ForgotHeading>
              <div>
                <ForgotPara>Please enter the OTP we’ve send to</ForgotPara>
                <div className="email-section">
                  <p className="user-email">{userEmail}</p>
                  {/* <img className="" src={updateIcon} /> */}
                </div>
              </div>
            </ForgotBox>
            <InputBox>
              <Formik
                initialValues={{ otp: "" }}
                enableReinitialize
                // validate={loginValidator}
                // validateOnChange
                onSubmit={handleOtpVerify}
              >
                {(formikBag) => {
                  return (
                    <Form className={classes.formStyleOnly}>
                      <Field name="otp">
                        {({ field }) => (
                          <div className="verify-input-box">
                            <OtpInput
                              {...field}
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              inputStyle="otp-input"
                              containerStyle="otp-container"
                            />
                          </div>
                        )}
                      </Field>

                      <ResendOtp onClick={handleResend}>Resend OTP</ResendOtp>
                      <LoginBtnWrapper>
                        <LoginButton>Verify</LoginButton>
                      </LoginBtnWrapper>
                    </Form>
                  );
                }}
              </Formik>
            </InputBox>

            <div style={{ position: "absolute", bottom: "0", width: "95%" }}>
              <img alt="dot-img" src={dotImage} style={{ width: "100%" }} />
            </div>
          </TextWrapper>
        </Column1>
      </InfoContainer>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtpVerify));