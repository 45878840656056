import React, { useState, useEffect } from "react";

import {
  InfoContainer,
  Column1,
  TextWrapper,
  SignupBox,
  SignupHeading,
  SignupPara,
  InputBox,
  LoginButton,
  LogoSarox,
} from "./SignupElements";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../../components/Input";
import InputLogin from "../../../components/InputLogin";
import Overlay from "../../../components/Overlay";
import axios from "../../../axios";
import { signUpValidator } from "../../../utils/validators";
import logoAboveSignup from "../../../assets/images/logo-white.png";
import dotImage from "../../../assets/images/dot-img.png";
import "./Signup.css";
import "../../../components/LoginSection/locationdropdown.css";
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  formStyle: {
    width: "100%",
    padding: "2rem",
    height: "80vh",
    overflow: "scroll",
  },
  "@media (max-width: 780px)": {
    formStyle: {
      padding: "1.8rem",
    },
  },
  "@media (max-width: 480px)": {
    formStyle: {
      padding: "1.3rem",
    },
  },
  formStyleOnly: {
    width: "100%",
  },
}));

const SignUp = ({
  lightBg,
  imgStart,
  img,
  pageHeading,
  pagePara,
  form,
  setUsers,
  userData,
  defaultState,
  setDefaultState,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [signUpformValues, setSignUpformValues] = useState({
    company_name: "",
    company_code: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    country_code: "",
    country_name: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    getCompanyCode();
  }, []);

  const getCompanyCode = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get("/company_list");

      const options = data.company_codes?.map(company => ({ value: company, label: company }));
      setCompanyOptions(options);
    } catch (error) {
      console.log("codeerr", error.response)
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUP = async (values) => {
    setIsLoading(true);

    var formvalues = {
      company_name: values.company_name,
      company_code: values.company_code,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile_no: values.mobile_no,
      country_code: values.country_code,
      country_name: values.country_name,
      password: values.password,
    };

    setSignUpformValues({
      ...formvalues,
      confirm_password: values.confirm_password,
    });

    try {
      const { data } = await axios.post("/user_reg", formvalues);

      if (data.status === 200) {
        setSignUpformValues(data);

        localStorage.setItem("id", data?.data?.id);
        localStorage.setItem("token", data?.token);
        localStorage.setItem("email", data?.data?.email);
        localStorage.setItem("userData", JSON.stringify(data?.data));
        setUsers(data.data);

        history.push("/otp-verify");

        toast.success("Please verify your Email Address", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        if (error.response.data?.error?.email) {
          toast.error(`${error.response.data?.error?.email[0]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(`${error.response.data?.error?.mobile_no[0]}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <InfoContainer>
        <Column1>
          <TextWrapper>
            <SignupBox className="pb-2">
              {/* <LogoSarox>
                <img className="web-logo" src={logoAboveSignup} />
              </LogoSarox> */}
              <SignupHeading>Sign Up</SignupHeading>
              <SignupPara>Login and start monitoring the machines</SignupPara>
            </SignupBox>
            <InputBox>
              {/* <Formik
                enableReinitialize
                initialValues={signUpformValues}
                validate={signUpValidator}
                validateOnChange
                onSubmit={handleSignUP}
              >
                {(formikBag) => {
                  return (
                    <Form className={classes.formStyleOnly}>
                      <div className="signup-input-container">
                        <div className="w-100">
                          <Field name="first_name">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                value={formikBag.values.first_name}
                                onChange={(e) => {
                                  if (e.target.value.length <= 20) {
                                    formikBag.setFieldValue(
                                      "first_name",
                                      e.target.value
                                    );
                                  }
                                }}
                                error={
                                  formikBag.touched.first_name &&
                                  formikBag.errors.first_name
                                    ? formikBag.errors.first_name
                                    : null
                                }
                                className="form-control signup-input-style"
                                placeholder="First name"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="w-100">
                          <Field name="last_name">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                value={formikBag.values.last_name}
                                onChange={(e) => {
                                  if (e.target.value.length <= 20) {
                                    formikBag.setFieldValue(
                                      "last_name",
                                      e.target.value
                                    );
                                  }
                                }}
                                error={
                                  formikBag.touched.last_name &&
                                  formikBag.errors.last_name
                                    ? formikBag.errors.last_name
                                    : null
                                }
                                className="form-control signup-input-style"
                                placeholder="Last name"
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="signup-input-container">
                        <div className="w-100">
                          <Field name="email">
                            {({ field }) => (
                              <Input
                                {...field}
                                type="text"
                                value={formikBag.values.email}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "email",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.email &&
                                  formikBag.errors.email
                                    ? formikBag.errors.email
                                    : null
                                }
                                className="form-control signup-input-style"
                                placeholder="Email"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="w-100">
                          <Field name="phone">
                            {({ field }) => (
                              <div className="py-2">
                                <PhoneInput
                                  {...field}
                                  country="ca"
                                  type="phone"
                                  value={formikBag.values.whole_number}
                                  countryCodeEditable={false}
                                  onChange={(phone, data) => {
                                    formikBag.setFieldValue(
                                      "country_name",
                                      data.name
                                    );
                                    formikBag.setFieldValue(
                                      "country_code",
                                      data.format.slice(0, 1) + data.dialCode
                                    );
                                    formikBag.setFieldValue(
                                      "mobile_no",
                                      phone.slice(data.dialCode.length)
                                    );
                                  }}
                                  error={
                                    formikBag.touched.mobile_no &&
                                    formikBag.errors.mobile_no
                                      ? formikBag.errors.mobile_no
                                      : null
                                  }
                                  className="form-control signup-input-style"
                                  placeholder="Mobile Number"
                                />
                                {formikBag.touched.mobile_no &&
                                  formikBag.errors.mobile_no && (
                                    <p
                                      style={{
                                        paddingTop: 5,
                                        fontSize: 13,
                                        color: "red",
                                      }}
                                    >
                                      {formikBag.errors.mobile_no}
                                    </p>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="signup-input-container">
                        <div className="w-100">
                          <Field name="password">
                            {({ field }) => (
                              <InputLogin
                                {...field}
                                type="password"
                                value={formikBag.values.password}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.password &&
                                  formikBag.errors.password
                                    ? formikBag.errors.password
                                    : null
                                }
                                className="form-control signup-input-style"
                                placeholder="Password"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="w-100">
                          <Field name="confirm_password">
                            {({ field }) => (
                              <InputLogin
                                {...field}
                                type="password"
                                value={formikBag.values.confirm_password}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "confirm_password",
                                    e.target.value
                                  );
                                }}
                                error={
                                  formikBag.touched.confirm_password &&
                                  formikBag.errors.confirm_password
                                    ? formikBag.errors.confirm_password
                                    : null
                                }
                                className="form-control signup-input-style"
                                placeholder="Confirm Password"
                              />
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="company-container">
                        <div className="w-50">
                          <Field name="company_code">
                            {({ field }) => (
                              <div className="py-2">
                                <Select
                                  {...field}
                                  isMulti={false}
                                  isSearchable={false}
                                  placeholder="Company code"
                                  className="form-control p-0"
                                  options={companyOptions}
                                  value={companyCode.map(title => ({ label: title, value: title }))}
                                  onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                      const selectedCompanyCode = selectedOption.map(option => option.value);
                                      setCompanyCode(selectedCompanyCode);
                                      formikBag.setFieldValue("company_code", selectedCompanyCode);
                                    } else {
                                      setCompanyCode(selectedOption ? [selectedOption.value] : []);
                                      formikBag.setFieldValue("company_code", selectedOption ? selectedOption.value : '');
                                    }
                                  }}
                                  error={
                                    formikBag.touched.company_code &&
                                    formikBag.errors.company_code
                                      ? formikBag.errors.company_code
                                      : null
                                  }
                                />
                                {formikBag.touched.company_code &&
                                  formikBag.errors.company_code && (
                                    <p
                                      style={{
                                        paddingTop: 5,
                                        fontSize: 13,
                                        color: "red",
                                      }}
                                    >
                                      {formikBag.errors.company_code}
                                    </p>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className="signup-button">
                        <LoginButton type="submit">Sign Up</LoginButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik> */}
            </InputBox>

            <p className="login-bottom-text">
              Already have an account?{" "}
              <Link to="/" className="redirect-signup">
                Log In
              </Link>
            </p>

            <div style={{ position: "absolute", bottom: "0", width: "95%" }}>
              <img alt="dot-img" src={dotImage} style={{ width: "100%" }} />
            </div>
          </TextWrapper>
        </Column1>
      </InfoContainer>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
