import axios from "axios";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa"; // Ensure FaPlus is imported


const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const UploadImage = async (file) => {

  try {
    // Convert image file to base64
    const base64 = await convertToBase64(file);
    const base64Parts = base64.split(",");
    const data = base64Parts[1];
    // Now send the base64 image string to the API
    // const { data } = await axios.post(
    //   "https://game.hewe.club/fileUpload",
    //   { image: base64Image }, // Send as part of a JSON object
    //   {
    //     headers: {
    //       "Content-Type": "application/json", // Change content type to JSON
    //     },
    //   }
    // );




    toast.success("Image Uploaded successfully");
    return data;
  } catch (error) {
    toast.error("Failed to upload profile image", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
