import React, { useState, useEffect } from "react";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
} from "./DashboardElement";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../Input";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from "moment";
import { Field, Form } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.css";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    height: "500px",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  tableMainContainer: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 0px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#ffffff",
  },
  textMiddle: {
    padding: "0.6rem 0px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#001050",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const [tableData, SetTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filterKey, setFilterKey] = useState("motor_id"); // State to hold the filter key

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // getMotordata();
  }, []);

  const getMotordata = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get(`/user/motor/list`, {
        headers: {
          Authorization: `${userToken}`,
        },
      });

      if (data?.status === 200) {
        if (data?.is_block) {
          alert(
            "Your access has been restricted by the administrator. Please contact support for further assistance."
          );
          history.push("/");
        } else {
          SetTableData(data?.data);
        }
      }
    } catch (error) {
      if (error?.response.status === 404) {
        alert(
          "We apologize, but no motor has been assigned at this time. Please check again later."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const options = [
    { value: "motor_id", label: "Motor Id" },
    { value: "location", label: "Location" },
    { value: "kilowatt", label: "Kilowatt" },
    { value: "voltage", label: "Voltage" },
  ];

  const handleChangeFilter = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFilterKey(selectedOption.value);
  };

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading>
              {/* <DashHeading>Dashboard</DashHeading> */}
            </DashboardHeading>

            {/* <Paper className={classes.paperTableHeight}>
              <>
                <TableContainer className={classes.tableMainContainer}>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell style={{ width: "140px" }}>
                          <Select
                            defaultValue={selectedOption}
                            onChange={handleChangeFilter}
                            options={options}
                            isSearchable={false}
                            isMulti={false}
                            placeholder="Filter"
                          />
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Motor Name
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Power Circuit
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Power Quality
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Insulation
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Rotor
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Stator
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>
                          Airgap
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((category, index) => (
                        <TableRow key={category.motor_id}>
                          <TableCell className={classes.textMiddle}>
                            <div>
                              {filterKey === "location"
                                ? category.locations
                                : category.motor_id}
                            </div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "motor_name", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "power_circuit", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "power_quality", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "insulation", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "rotor", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "stator", "")}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            <div>{get(category, "airgap", "")}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className={classes.tablePaginationStyle}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            </Paper> */}
          </DashboardWrapper>
        </DashboardContainer>
      </div>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
